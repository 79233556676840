.App {
  text-align: center;

}


.App-logo {

  height: 40vmin;
  pointer-events: none;
}

.instaP {
  margin-top: 15px;
  border: 2px solid #ff69b4;
  /* Puedes cambiar el color del borde según tus preferencias */
  border-radius: 5px;
  cursor: pointer;
  /* Cambia el cursor al pasar sobre la imagen para indicar que es clickable */
}

.instaP:hover {
  transform: scale(1.05);
  /* Cambia el tamaño de la imagen al hacer hover */
  cursor: pointer;
  /* Cambia el cursor al pasar sobre la imagen para indicar que es clickable */
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    width: 15rem;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ff69b4cf;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}